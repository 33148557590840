import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dodge-game" }
const _hoisted_2 = { class: "dodge-score" }
const _hoisted_3 = { class: "section-content" }
const _hoisted_4 = { class: "dodge-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_DodgeFrame = _resolveComponent("DodgeFrame")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Banner),
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[1] || (_cache[1] = _createElementVNode("p", null, [
          _createTextVNode(" 移动： ↑↓←→"),
          _createElementVNode("br"),
          _createTextVNode(" 减速： 按住左Shift ")
        ], -1)),
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(" 最高分：" + _toDisplayString(_ctx.scoreString(_ctx.highestScore)), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" 分　数：" + _toDisplayString(_ctx.scoreString(_ctx.score)), 1)
        ])
      ]),
      _createVNode(_component_DodgeFrame),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", {
          class: "section-content",
          style: _normalizeStyle({color: _ctx.statusStyle()})
        }, _toDisplayString(_ctx.status()), 5)
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}