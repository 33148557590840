import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "desc desc-main",
  id: "main-info"
}
const _hoisted_2 = { class: "section section-main" }
const _hoisted_3 = { class: "sub" }
const _hoisted_4 = { class: "button-box" }
const _hoisted_5 = {
  href: "https://m.tb.cn/h.fwEAfqD?sm=63872f?tk=F3D32LqIOiO",
  target: "_blank"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  class: "desc desc-special",
  id: "special-info"
}
const _hoisted_8 = { class: "section section-special" }
const _hoisted_9 = { class: "sub" }
const _hoisted_10 = { class: "button-box" }
const _hoisted_11 = {
  href: "https://m.tb.cn/h.fvApb7c?sm=afff61?tk=Grz32Lqrzxf",
  target: "_blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnvironmentOutlined = _resolveComponent("EnvironmentOutlined")!
  const _component_ShoppingOutlined = _resolveComponent("ShoppingOutlined")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[5] || (_cache[5] = _createElementVNode("h1", {
          class: "section-title",
          style: {"color":"rgb(0, 51, 153)"}
        }, [
          _createTextVNode("槐南东方Only同人展会"),
          _createElementVNode("br"),
          _createTextVNode("天桥幻想百景")
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("h2", null, "2022年8月6日 09:30 ~ 16:30", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "section-content" }, [
          _createTextVNode(" 2022年的开端笼罩在疫情的阴霾之下，"),
          _createElementVNode("br"),
          _createTextVNode(" 是否许久未与同好们相聚？"),
          _createElementVNode("br"),
          _createTextVNode(" 就在今年夏天的津门，"),
          _createElementVNode("br"),
          _createTextVNode(" 槐南茶馆将与各社团联合"),
          _createElementVNode("br"),
          _createTextVNode(" 为各位东方爱好者带来一场久违的东方同人线下活动！ ")
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tags" }, [
            _createElementVNode("span", null, "# 同人展会")
          ], -1)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("a", {
              onClick: _cache[0] || (_cache[0] = () => _ctx.scrollToElmWithId('venue-info'))
            }, [
              _createVNode(_component_EnvironmentOutlined, { class: "icon" }),
              _cache[2] || (_cache[2] = _createElementVNode("span", null, "查看地图", -1))
            ]),
            _createElementVNode("a", _hoisted_5, [
              _createVNode(_component_ShoppingOutlined, { class: "icon" }),
              _cache[3] || (_cache[3] = _createElementVNode("span", null, "购买门票", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("img", {
        src: `${_ctx.COS_URL}/img/THO1-main-poster.jpg`,
        alt: "Poster of main doujin event depicting Shikieiki and Komachi",
        class: "poster"
      }, null, 8, _hoisted_6)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[14] || (_cache[14] = _createElementVNode("img", {
        src: "static/image/THO1-special-poster.png",
        alt: "Poster of special xiangsheng event depicting Shion and Lunasa",
        class: "poster"
      }, null, -1)),
      _createElementVNode("div", _hoisted_8, [
        _cache[11] || (_cache[11] = _createElementVNode("h1", {
          class: "section-title",
          style: {"color":"rgb(255, 204, 102)"}
        }, "槐南茶馆相声专场", -1)),
        _cache[12] || (_cache[12] = _createElementVNode("h2", null, "2022年8月6日 18:30 ~ 20:30", -1)),
        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "section-content" }, [
          _createTextVNode(" 喝茶、嗑瓜子、听相声……"),
          _createElementVNode("br"),
          _createTextVNode(" 代代天津人传承的风俗文化与东方同人交融碰撞，"),
          _createElementVNode("br"),
          _createTextVNode(" 尽在本届槐南相声专场！"),
          _createElementVNode("br"),
          _createTextVNode(" 诚邀来自天南海北的各位"),
          _createElementVNode("br"),
          _createTextVNode(" 享受这场由东方与传统文化交织出的线下演出！ ")
        ], -1)),
        _createElementVNode("div", _hoisted_9, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "tags" }, [
            _createElementVNode("span", null, "# 相声专场")
          ], -1)),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("a", {
              onClick: _cache[1] || (_cache[1] = () => _ctx.scrollToElmWithId('venue-info'))
            }, [
              _createVNode(_component_EnvironmentOutlined, { class: "icon" }),
              _cache[8] || (_cache[8] = _createElementVNode("span", null, "查看地图", -1))
            ]),
            _createElementVNode("a", _hoisted_11, [
              _createVNode(_component_ShoppingOutlined, { class: "icon" }),
              _cache[9] || (_cache[9] = _createElementVNode("span", null, "购买门票", -1))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}