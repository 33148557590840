import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { id: "banner" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        (_ctx.laugh)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: "Huainan-Banner",
              id: "huainan-banner",
              src: _ctx.logoSrc,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.randomLaughter()))
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              alt: "Huainan-Banner",
              id: "huainan-banner",
              src: _ctx.logoSrc
            }, null, 8, _hoisted_3)),
        _cache[1] || (_cache[1] = _createElementVNode("audio", {
          src: "https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/audio%2Flaughter.mp3",
          id: "laughter"
        }, null, -1)),
        _cache[2] || (_cache[2] = _createElementVNode("audio", {
          src: "https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/audio%2Flaughter_short.mp3",
          id: "laughter-short"
        }, null, -1))
      ]),
      _: 1
    })
  ]))
}