
import { Vue, Options } from 'vue-class-component';
import { ShoppingOutlined, EnvironmentOutlined } from '@ant-design/icons-vue';
import { inject } from 'vue';
import { SmoothScrollOptions } from 'vue3-smooth-scroll';

import Arrow from '../components/Arrow.vue';
import { COS_URL } from '@/constants';

@Options({
  components: {
    Arrow,
    ShoppingOutlined,
    EnvironmentOutlined
  }
})
export default class EventDescription extends Vue {
  smoothScroll = inject('smoothScroll') as (arg: SmoothScrollOptions) => void
  scrollToElmWithId = (id: string) => {
    this.smoothScroll({
      scrollTo: document.getElementById(id) as Element,
      duration: 500
    })
  }
  readonly COS_URL = COS_URL; 
}
