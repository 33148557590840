<template>
  <div class="venue-map-wrapper">
    <div id="venue-map-container">
    </div>
  </div>
</template>

<script>
import { Vue } from 'vue-class-component'
import AMapLoader from '@amap/amap-jsapi-loader'
import { shallowRef } from '@vue/reactivity'
export default class VenueMap extends Vue {
  map = shallowRef(null)
  pos = [117.19198, 39.129334]

  init() {
    AMapLoader.load({
      key: "daa8c2b0f0830ebf774e3bbb06b90284", 
      version: "2.0",
      plugins: ['AMap.ElasticMarker']
    }).then((AMap) => {
      this.map = new AMap.Map("venue-map-container", {
        zoom: 16,
        center: this.pos, 
        mapStyle: 'amap://styles/normal',
        viewMode: '3D',
        zooms: [10, 20]
      })
      let marker = new AMap.ElasticMarker({
        styles: [
          {
            icon: {
              img: 'static/image/venue.svg',
              size: [72, 128],
              anchor: 'bottom-center',
              fitZoom: 17,
              scaleFactor: 2,
              maxScale: 1.2,
              minScale: 0.4
            },
            label: {
              content: '华信半岛酒店',
              position: 'BM'
            }
          }
        ],
        zoomStyleMapping: {
          10: 0,
          11: 0,
          12: 0,
          13: 0,
          14: 0,
          15: 0,
          16: 0,
          17: 0,
          18: 0,
          19: 0,
          20: 0,
        },
        position: this.pos,
        zooms: [10, 20],
        extData: {
          id: 'venue-marker'
        }
      })
      this.map.add(marker)
    }).catch(e => {
      console.log(e)
    })
  }

  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode:'df70d5c6dd35d9bff0fce66490094d0c'
    }
    this.init()
  }
}
</script>

<style>
.venue-map-wrapper {
  width: 100%;
  height: 100%;
}

.amap-icon img {
  position: relative;
}

#venue-map-container {
  width: 100%;
  height: 100%;
  margin: 0px;
}
</style>