import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "huangtu" }
const _hoisted_2 = { id: "sun" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { id: "stars" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("embed", {
        src: _ctx.sunSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_3)
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { id: "moon" }, [
      _createElementVNode("embed", {
        class: "moon",
        src: "https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2Fmoon5.svg",
        type: "image/svg+xml"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("embed", {
        style: {"left":"20%","top":"5%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_5),
      _createElementVNode("embed", {
        style: {"left":"23%","top":"7%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_6),
      _createElementVNode("embed", {
        style: {"left":"7%","top":"11%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_7),
      _createElementVNode("embed", {
        style: {"left":"13%","top":"4%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_8),
      _createElementVNode("embed", {
        style: {"left":"43%","top":"9%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_9),
      _createElementVNode("embed", {
        style: {"left":"64%","top":"12%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_10),
      _createElementVNode("embed", {
        style: {"left":"56%","top":"15%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_11),
      _createElementVNode("embed", {
        style: {"left":"74%","top":"10%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_12),
      _createElementVNode("embed", {
        style: {"left":"82%","top":"3%"},
        src: _ctx.starSrc,
        type: "image/svg+xml"
      }, null, 8, _hoisted_13)
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div id=\"game\" data-v-e9e77f24><div id=\"dino-scoreboard\" data-v-e9e77f24><span data-v-e9e77f24>最高分: <span id=\"dino-hiscore\" data-v-e9e77f24>000, 000, 000</span></span><br data-v-e9e77f24><span data-v-e9e77f24>得分　: <span id=\"dino-score\" data-v-e9e77f24>000, 000, 000</span></span></div><div id=\"dino-grass\" data-v-e9e77f24></div></div><div id=\"huangtu-text\" data-v-e9e77f24><span id=\"ninhao\" data-v-e9e77f24>您好：</span><div id=\"tianjinTHO\" data-v-e9e77f24><span id=\"tianjin\" data-v-e9e77f24>槐南</span><embed class=\"svg-char\" id=\"T\" src=\"https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2FT.svg\" type=\"image/svg+xml\" data-v-e9e77f24><embed class=\"svg-char\" id=\"H\" src=\"https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2FH.svg\" type=\"image/svg+xml\" data-v-e9e77f24><embed class=\"svg-char\" id=\"O\" src=\"https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2FO.svg\" type=\"image/svg+xml\" data-v-e9e77f24></div><span class=\"wavy zaidongle\" data-v-e9e77f24><span data-v-e9e77f24>　</span><span data-v-e9e77f24>在</span><span id=\"dong\" data-v-e9e77f24>动</span><span data-v-e9e77f24>了</span><span data-v-e9e77f24>！</span></span><div id=\"bottom\" data-v-e9e77f24><a id=\"kuailaiba\" href=\"https://jq.qq.com/?_wv=1027&amp;k=kw71sJwb\" target=\"_blank\" data-v-e9e77f24>快来吧！<sub data-v-e9e77f24>点击加群</sub></a><span id=\"qq-group-number\" data-v-e9e77f24>群号: 934078619</span></div></div>", 2))
  ]))
}