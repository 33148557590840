import Renderer, { Sprite, SpriteAnimation } from './Renderer'
import Rectangle from './Rectangle'
import Vector from './Vector'
import Timer from './Timer'

const imageFromSrc = (src: string) => {
  const img = new Image()
  img.src = src
  return img
}
const formatScoreString = (score: number): string => `${Math.floor(score / 1000000).toString().padStart(3, '0')}, ${Math.floor(score / 1000).toString().padStart(3, '0')}, ${(score % 1000).toString().padStart(3, '0')}`

export default class Dinosaur {
    private size: Vector
    private renderer: Renderer
    private activeSprites: Record<string, Sprite> = {}
    private groundLevel = 20
    private previousTick = 0
    private maxJumps = 2
    private scoreSpan = document.getElementById('dino-score') as HTMLSpanElement
    private hiscoreSpan = document.getElementById('dino-hiscore') as HTMLSpanElement
    private sunDiv = document.getElementById('sun') as HTMLDivElement
    private huangtuDiv = document.getElementById('huangtu') as HTMLDivElement
    private night = false
    private assets = {
      sunflower: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img/minigame/sunflower-concat.png'),
      cloud: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2Fcloud.svg'),
      longCloud: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fsvg%2Fcloud_long.svg'),
      mahua: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fminigame%2Fmahua_slim.png'),
      grass: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fminigame%2Fgrass.png'),
      bush: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fminigame%2Fbush.png'),
      bullet: imageFromSrc('https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img%2Fminigame%2Fbullet.png')
    }

    private timers = {
      daylightCycle: new Timer(),
      cloudGeneration: new Timer(),
      mahuaGeneration: new Timer(),
      grassGeneration: new Timer(),
      scoreIncrement: new Timer(),
      difficultyIncrease: new Timer()
    }

    private state = {
      started: false,
      score: 0,
      remainingJumps: 2,
      difficulty: 1
    }

    constructor (parentDiv: HTMLDivElement) {
      this.renderer = new Renderer(parentDiv, this.activeSprites, 3, true)
      this.size = this.renderer.pixelSize.clone()

      document.addEventListener('keydown', this.keyDownHandler)
      document.addEventListener('keyup', this.keyUpHandler)

      const ob = new ResizeObserver(() => {
        this.renderer.resize()
        this.size = this.renderer.pixelSize.clone()
      })
      ob.observe(parentDiv)

      this.init()
      requestAnimationFrame(this.mainLoop)
    }

    private init (): void {
      this.state.started = false
      this.state.score = 0
      this.state.remainingJumps = 2
      this.state.difficulty = 1

      this.timers.mahuaGeneration.reset()
      this.timers.difficultyIncrease.reset()
      this.timers.scoreIncrement.reset()
      this.renderer.clearCanvas()
      for (const spriteId in this.activeSprites) {
        if (spriteId.slice(0, 5) !== 'cloud') {
          delete this.activeSprites[spriteId]
        }
      }
      this.activeSprites.sunflower = new Sprite(
        new SpriteAnimation(this.assets.sunflower, {
          frames: 60,
          cols: 60,
          singleSpriteSize: new Vector(256, 256)
        }), Rectangle.fromNumbers(this.size.x / 2 - 80, this.size.y - 160, 160, 160), Vector.Zero(), 1)
      this.activeSprites['grass-0000000000000'] = new Sprite(
        this.assets.grass,
        Rectangle.fromNumbers(this.size.x * 0.2, this.size.y - 128, 128, 128),
        Vector.Zero(),
        2)
      this.activeSprites['bush-0000000000000'] = new Sprite(
        this.assets.bush,
        Rectangle.fromNumbers(this.size.x * 0.5, this.size.y - 128, 256, 128),
        Vector.Zero(),
        2);
      (document.getElementById('huangtu-text') as HTMLDivElement).style.display = 'block';
      (document.getElementById('dino-scoreboard') as HTMLDivElement).style.display = 'none'
    }

    keyDownHandler: (e: KeyboardEvent) => void = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'w':
        case ' ':
        case 'ArrowUp':
          if (!this.state.started) {
            this.startGame()
          }
          if (this.state.remainingJumps > 0) {
            this.state.remainingJumps -= 1
            this.activeSprites.sunflower.velocity.y = -20
          }
          break
        case 'a':
        case 'ArrowLeft':
          if (this.state.started) {
            this.activeSprites.sunflower.velocity.x = -10
          }
          break
        case 'd':
        case 'ArrowRight':
          if (this.state.started) {
            this.activeSprites.sunflower.velocity.x = 10
          }
          break
        case 's':
        case 'ArrowDown':
          this.activeSprites.sunflower.velocity.y = 25
          break
      }
    }

    keyUpHandler: (e: KeyboardEvent) => void = (e: KeyboardEvent) => {
      switch (e.key) {
        case 'a':
        case 'ArrowLeft':
          if (this.activeSprites.sunflower.velocity.x < 0) {
            this.activeSprites.sunflower.velocity.x = 0
          }
          break
        case 'd':
        case 'ArrowRight':
          if (this.activeSprites.sunflower.velocity.x > 0) {
            this.activeSprites.sunflower.velocity.x = 0
          }
          break
      }
    }

    private startGame (): void {
      this.state.started = true;
      (document.getElementById('huangtu-text') as HTMLDivElement).style.display = 'none';
      (document.getElementById('dino-scoreboard') as HTMLDivElement).style.display = 'block'
      this.hiscoreSpan.textContent = formatScoreString(this.getHiscore())
      this.activeSprites['grass-0000000000000'].velocity.x = -10
      this.activeSprites['bush-0000000000000'].velocity.x = -10
    }

    private gameover (): void {
      if (this.state.score > this.getHiscore()) {
        this.setHiScore(this.state.score)
      }
      this.init()
    }

    private sunSet: () => void = () => {
      this.huangtuDiv.classList.add('dark-mode')
      this.night = true
    }

    private sunRise: () => void = () => {
      this.huangtuDiv.classList.remove('dark-mode')
      this.night = false
    }

    private landed (sprite: Sprite): boolean {
      if (sprite.currentRect !== null && sprite.currentRect.pos.y >= this.size.y - this.groundLevel - sprite.currentRect.size.y) {
        return true
      }
      return false
    }

    private deleteOutrangedSprites (): void {
      const maxRange = 500
      for (const spriteId in this.activeSprites) {
        const sprite: Sprite = this.activeSprites[spriteId]
        if (sprite.currentRect !== null &&
          (sprite.currentRect.pos.x < -maxRange || sprite.currentRect.pos.x > this.size.x + maxRange ||
          sprite.currentRect.pos.y < -maxRange || sprite.currentRect.pos.y > this.size.y + maxRange)) {
          delete this.activeSprites[spriteId]
        }
      }
    }

    private generateRandomCloud (): void {
      const isLongCloud = Math.random() < 0.2
      const newCloud = new Sprite(isLongCloud ? this.assets.longCloud : this.assets.cloud, Rectangle.fromNumbers(this.size.x, Math.random() * 50, isLongCloud ? 512 : 128, 128), new Vector(-1, 0))
      this.activeSprites[`cloud-${Math.random().toString(16).slice(2)}`] = newCloud
    }

    private generateMahua (offsetX?: number): void {
      const size = Math.random() * 128 + 128
      const mahua: Sprite = new Sprite(this.assets.mahua, Rectangle.fromNumbers(this.size.x + (offsetX || 0), this.size.y - size - this.groundLevel, 48, size), new Vector(-10 * this.state.difficulty, 0))
      this.activeSprites[`mahua-${Math.random().toString(16).slice(2)}`] = mahua
    }

    private generateGrass (): void {
      const grass: Sprite = new Sprite(
        this.assets.grass,
        Rectangle.fromNumbers(this.size.x, this.size.y - 128, 128, 128),
        new Vector(-10 * this.state.difficulty, 0),
        2
      )
      this.activeSprites[`grass-${Math.random().toString(16).slice(2)}`] = grass
    }

    private generateBush (): void {
      const bush: Sprite = new Sprite(
        this.assets.bush,
        Rectangle.fromNumbers(this.size.x, this.size.y - 128, 256, 128),
        new Vector(-10 * this.state.difficulty, 0),
        2
      )
      this.activeSprites[`bush-${Math.random().toString(16).slice(2)}`] = bush
    }

    private getHiscore (): number {
      if (localStorage.getItem('dinosaur-hiscore') === null) {
        this.setHiScore(0)
        return 0
      }
      return parseInt(localStorage.getItem('dinosaur-hiscore') as string)
    }

    private setHiScore (score: number): void {
      localStorage.setItem('dinosaur-hiscore', score.toString())
    }

    mainLoop = (timestamp: number): void => {
      const deltaT = timestamp - this.previousTick
      this.previousTick = timestamp
      const sunflower = this.activeSprites.sunflower
      if (this.timers.daylightCycle.timeSinceLastReset() > 30000) {
        this.timers.daylightCycle.reset()
        if (this.night) {
          this.sunRise()
        } else {
          this.sunSet()
        }
      }

      for (const spriteId in this.activeSprites) {
        this.activeSprites[spriteId].move(deltaT)
      }

      if (this.timers.cloudGeneration.timeSinceLastReset() > 1000) {
        if (Math.random() < 0.3) {
          this.generateRandomCloud()
        }
        this.timers.cloudGeneration.reset()
      }
      if (this.state.started) {
        if (this.timers.scoreIncrement.timeSinceLastReset() > 64) {
          this.timers.scoreIncrement.reset()
          this.scoreSpan.textContent = formatScoreString(this.state.score)
          this.state.score++
        }
        if (this.timers.difficultyIncrease.timeSinceLastReset() > 5000 && this.state.difficulty <= 2.5) {
          this.state.difficulty += 0.03
          this.timers.difficultyIncrease.reset()
        }
        if (this.timers.grassGeneration.timeSinceLastReset() > 500) {
          this.timers.grassGeneration.reset()
          if (Math.random() < 0.3) this.generateGrass()
          else if (Math.random() < 0.2) this.generateBush()
        }
        if (this.timers.mahuaGeneration.timeSinceLastReset() > 750) {
          this.timers.mahuaGeneration.reset()
          if (Math.random() < 0.4) {
            this.generateMahua()
            if (Math.random() < 0.3) {
              this.generateMahua(40)
              this.generateMahua(80)
            }
          }
        }
      }

      const sunflowerRect: Rectangle = sunflower.currentRect as Rectangle
      if (this.landed(sunflower)) {
        this.state.remainingJumps = this.maxJumps
        if (sunflower.currentRect !== null) {
          sunflower.currentRect.pos.y = this.size.y - this.groundLevel - sunflower.currentRect.size.y
        }
        sunflower.velocity.y = 0
      } else if (this.state.started) {
        sunflower.velocity.y += deltaT / 16
      }

      if (sunflowerRect.overlapWith(Rectangle.fromNumbers(-10, 0, 10, this.size.y))) {
        sunflowerRect.pos.x = 0
      }
      if (sunflowerRect.overlapWith(Rectangle.fromNumbers(this.size.x, 0, 10, this.size.y))) {
        sunflowerRect.pos.x = this.size.x - sunflowerRect.size.x
      }
      const sunflowerHitbox: Rectangle = new Rectangle(Vector.add(sunflowerRect.pos, new Vector(41.25, 33.125)), new Vector(80, 48.75))
      for (const spriteId in this.activeSprites) {
        if (spriteId.slice(0, 5) === 'mahua') {
          const mahua = this.activeSprites[spriteId]
          if (mahua.currentRect !== null && mahua.currentRect.overlapWith(sunflowerHitbox)) {
            this.gameover()
          }
        }
      }

      this.renderer.render()
      this.deleteOutrangedSprites()
      window.requestAnimationFrame(this.mainLoop)
    }
}
