<template>
  <router-view/>
  <div class="bg"></div>
</template>
<style>

body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.bg{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: rgb(248,234,213)  url(/static/image/bg.jpg) repeat center center;
  opacity: .375;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.wavy span {
  font-size:3em;
  display:table-cell;
  padding: 0 2px;
  line-height: 100px;
  animation:jump 1.2s infinite;
}
@keyframes jump {
  0% {
    transform:translateY(-15px);
  }
  50% {
    transform:translateY(15px);
  }
  100% {
    transform:translateY(-15px);
  }
}
.wavy span:nth-child(1) {
  animation-delay:0s;
}
.wavy span:nth-child(2) {
  animation-delay:.1s;
}
.wavy span:nth-child(3) {
  animation-delay:.2s;
}
.wavy span:nth-child(4) {
  animation-delay:.3s;
}
.wavy span:nth-child(5) {
  animation-delay:.4s;
}
.wavy span:nth-child(6) {
  animation-delay:.5s;
}
.wavy span:nth-child(7) {
  animation-delay:.6s;
}

h1.section-title {
  font-size: 32px;
}

h2.section-title {
  font-size: 28px;
}

h3.section-title {
  font-size: 24px;
}

h4.section-title {
  font-size: 20px;
}

p.section-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 2;
  letter-spacing: .1em;
}

</style>
