export const MB = 1048576

export const API_URL = process.env.VUE_APP_API_URL || ''
export const COS_URL = process.env.VUE_APP_COS_URL || ''

export const USER = {
  USERNAME: {
    LEN: {
      MIN: 1,
      MAX: 20
    }
  },
  PASSWORD: {
    LEN: {
      MIN: 5,
      MAX: 40
    }
  },
  AVATAR: {
    SIZE: {
      MAX: 5 * MB
    }
  }
}

export const TEXT = {
  LEN: {
    MAX: 4096
  }
}

export const WORK = {
  NAME: {
    LEN: {
      MIN: 1,
      MAX: 100
    }
  },
  PREVIEW: {
    AMOUNT: {
      MIN: 0,
      MAX: 1
    },
    SIZE: {
      MAX: 5 * MB
    }
  },
  AMOUNT: {
    MAX: 6
  }
}

export const PRICE = {
  PERMIT: 120,
  STAND: 200,
  STAND_SDL: 160
}
