
import { Options, Vue } from 'vue-class-component'
import QrcodeVue from 'qrcode.vue'
import { COS_URL } from '@/constants';

import { API_URL } from '@/constants'
import { NullLiteral } from '@babel/types';

enum TicketStatus {
  valid = 'valid',
  redeemed = 'redeemed',
  expired = 'expired',
  invalid = 'invalid'
}

enum EventName {
  THOnly01 = 'THOnly01'
}

enum TicketTypeName {
  THOnly = 'THOnly',
  Special = 'Special'
}

interface TicketTypeInfo {
  event: EventName;
  type: TicketTypeName;
}

enum SeatClass {
  economy = 'economy',
  business = 'business',
  first = 'first'
}

interface THOTicket {
  id: string; 
  status: TicketStatus;
  phone: string | null;
  type: TicketTypeInfo;
}

type SpecialTicket = THOTicket & {
  seatClass: SeatClass; 
  seatNo: string; 
  parent: null;
  children: THOTicket[];
}

@Options({
  components: {
    QrcodeVue
  }
})
export default class TicketDisplay extends Vue {
  showToggle: boolean = true;
  THOTicket: THOTicket | null = null;
  specialTicket: SpecialTicket | null = null;
  special: boolean = false;
  loading: boolean = true;
  readonly COS_URL = COS_URL;
  readonly seatClassDisplayName = {
    'economy': '普通座',
    'business': '一等座',
    'first': '特等座'
  }

  async getTicketInfo() {
    const ticketId = this.$route.params.ticketNumber;
    try {
      const resp = await fetch(`${API_URL}/ticket/${ticketId}`);
      if (resp.ok) {
        const ticket = await resp.json(); 
        if (ticket?.type?.type === TicketTypeName.Special) {

          const specialTicket: SpecialTicket = ticket;
          this.THOTicket = specialTicket.children[0];
          this.specialTicket = specialTicket;
          this.showToggle = true;

        } else if (ticket?.type?.type === TicketTypeName.THOnly) {

          this.THOTicket = ticket; 
          this.specialTicket = null;
          this.showToggle = false;
          this.special = false;

        } else {
          this.$router.push({path: '/404'});
        }
      } else {
        if (resp.status === 404) {
          this.$router.push({path: '/404'});
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  toggle() {
    this.special = !this.special;
  }

  mounted() {
    this.getTicketInfo();
  }

}
