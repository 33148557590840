export default class Vector {
  x: number;
  y: number;

  constructor (x: number, y: number) {
    this.x = x
    this.y = y
  }

  add (v: Vector): Vector {
    this.x += v.x
    this.y += v.y
    return this
  }

  multiply (n: number): Vector {
    this.x *= n
    this.y *= n
    return this
  }

  clone (): Vector {
    return new Vector(this.x, this.y)
  }

  equals (v: Vector): boolean {
    return this.x === v.x && this.y === v.y
  }

  static add (v1:Vector, v2:Vector): Vector {
    return v1.clone().add(v2)
  }

  static multiply (v: Vector, n: number): Vector {
    return v.clone().multiply(n)
  }

  static Zero (): Vector {
    return new Vector(0, 0)
  }

  static equals (v1: Vector, v2: Vector): boolean {
    return v1.equals(v2)
  }
}
