<template>
  <div class="ticket-center">
    <TicketDisplay />
  </div>
  <div class="bg-override"></div>
</template>

<script>
import { Options, Vue } from 'vue-class-component'
import TicketDisplay from '../components/TicketDisplay.vue'

@Options({
  components: {
    TicketDisplay
  }
})
export default class ViewTicket extends Vue{}
</script>

<style scoped>
.bg-override {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
  background: rgb(0,0,0);
  opacity: 1;
}

@media screen and (orientation: landscape) {
  @media (min-aspect-ratio: 16/9) {
    .ticket-center {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  @media (max-aspect-ratio: 16/9) {
    .ticket-center {
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
  }
}

@media screen and (orientation: portrait) {
  @media (min-aspect-ratio: 9/16) {
    .ticket-center {
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }
  @media (max-aspect-ratio: 9/16) {
    .ticket-center {
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
  }
}
</style>