
import { Options, Vue } from 'vue-class-component';

import Banner from '@/components/Banner.vue';
import Navbar from '@/components/Navbar.vue';
import Rectangle from '@/ts_modules/dinosaur/Rectangle';
import Renderer, { Sprite, SpriteAnimation } from '@/ts_modules/dinosaur/Renderer';
import Vector from '@/ts_modules/dinosaur/Vector';
import Footer from './Footer.vue';

@Options({
  components: {
    Navbar,
    Banner,
    Footer
  }
})
export default class NotFoundPage extends Vue {
  id = `sunflower-${Math.random()}`;

  mounted () {
    const animationDiv = document.getElementById(this.id) as HTMLDivElement
    const sunflowerSpriteSheet = new Image()
    sunflowerSpriteSheet.src = 'https://huainan-bucket-1308950613.cos.ap-beijing.myqcloud.com/img/minigame/sunflower-concat.png'
    const sunflowerAnimation = new SpriteAnimation(sunflowerSpriteSheet, {
      frames: 60,
      cols: 60,
      singleSpriteSize: new Vector(256, 256)
    })
    const renderer = new Renderer(animationDiv, {
      sunflower: new Sprite(sunflowerAnimation, Rectangle.fromNumbers(0, 0, 256, 256))
    }, 1, false)
    const render = () => {
      renderer.render()
      requestAnimationFrame(render)
    }
    requestAnimationFrame(render)
  }
}
