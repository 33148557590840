import Vector from './Vector'
import Rectangle from './Rectangle'
import Timer from './Timer'

interface AnimationInfo {
  frames: number
  singleSpriteSize: Vector
  cols: number
}

export class SpriteAnimation {
  spriteSheet: HTMLImageElement
  private info: AnimationInfo
  private frame = 0
  private _cropParams: Rectangle
  private frameUpdateTimer = new Timer()

  constructor (spriteSheet: HTMLImageElement, info: AnimationInfo) {
    this.spriteSheet = spriteSheet
    this.info = info
    this._cropParams = new Rectangle(Vector.Zero(), info.singleSpriteSize.clone())
  }

  private calculateCropPos (): Vector {
    return new Vector((this.frame % this.info.cols) * this.info.singleSpriteSize.x,
      Math.floor(this.frame / this.info.cols) * this.info.singleSpriteSize.y)
  }

  get cropParams (): Rectangle {
    return this._cropParams
  }

  nextFrame (): void {
    if (this.frameUpdateTimer.timeSinceLastReset() > 16) {
      this.frame++
      if (this.frame >= this.info.frames) {
        this.frame = 0
      }
      this._cropParams.pos = this.calculateCropPos()
      this.frameUpdateTimer.reset()
    }
  }
}

export class Sprite {
  src: CanvasImageSource | SpriteAnimation
  velocity: Vector
  prevRect: Rectangle | null
  currentRect: Rectangle | null
  zIndex: number

  constructor (src: CanvasImageSource | SpriteAnimation, pos: Rectangle, velocity?:Vector, zIndex?: number) {
    this.src = src
    this.prevRect = src instanceof SpriteAnimation ? pos.clone() : null
    this.currentRect = pos
    this.velocity = velocity || Vector.Zero()
    this.zIndex = zIndex || 0
  }

  updateRect (newRect: Rectangle): void {
    this.prevRect = this.currentRect
    this.currentRect = newRect.clone()
  }

  updatePos (newPos: Vector): void {
    this.prevRect = this.currentRect
    if (this.prevRect !== null) {
      this.currentRect = new Rectangle(newPos, this.prevRect.size.clone())
    }
  }

  move (deltaT: number): void {
    this.prevRect = this.currentRect
    if (this.prevRect !== null) {
      this.currentRect = new Rectangle(Vector.add(this.prevRect.pos, Vector.multiply(this.velocity, deltaT / 15)), this.prevRect.size.clone())
    }
  }

  drawOnCtx (ctx: CanvasRenderingContext2D, canvasRect: Rectangle): void {
    if (this.src instanceof SpriteAnimation) {
      const s: SpriteAnimation = this.src as SpriteAnimation
      ctx.drawImage(s.spriteSheet, s.cropParams.pos.x, s.cropParams.pos.y,
        s.cropParams.size.x, s.cropParams.size.y,
        canvasRect.pos.x, canvasRect.pos.y, canvasRect.size.x, canvasRect.size.y)
      s.nextFrame()
    } else {
      ctx.drawImage(this.src as CanvasImageSource, canvasRect.pos.x, canvasRect.pos.y,
        canvasRect.size.x, canvasRect.size.y)
    }
  }
}

export default class Renderer {
  private parentDiv: HTMLDivElement
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D
  private size: Vector
  private canvasRect: Rectangle
  private readonly activeSprites: Record<string, Sprite>

  constructor (parentDiv: HTMLDivElement, activeSprites: Record<string, Sprite>, zIndex: number, absolute: boolean) {
    this.parentDiv = parentDiv
    this.activeSprites = activeSprites

    const canvas = document.createElement('canvas')
    canvas.width = parentDiv.clientWidth
    canvas.height = parentDiv.clientHeight
    if (absolute) {
      canvas.style.position = 'absolute'
      canvas.style.left = '0'
      canvas.style.top = '0'
    }
    canvas.style.zIndex = zIndex.toString()
    this.size = new Vector(parentDiv.clientWidth, parentDiv.clientHeight)
    this.canvasRect = new Rectangle(Vector.Zero(), this.size)
    this.canvas = canvas
    this.parentDiv.appendChild(canvas)

    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    this.ctx = ctx
  }

  get pixelSize (): Vector {
    return this.size
  }

  resize (): void {
    this.size.x = this.parentDiv.clientWidth
    this.size.y = this.parentDiv.clientHeight
    this.canvas.width = this.size.x
    this.canvas.height = this.size.y
    this.canvas.style.width = `${this.size.x}px`
    this.canvas.style.height = `${this.size.y}px`
  }

  clearCanvas (): void {
    this.ctx.clearRect(0, 0, this.size.x, this.size.y)
  }

  render (): void {
    const needToErase: string[] = []
    const needToDraw: string[] = []
    for (const spriteId in this.activeSprites) {
      const sprite = this.activeSprites[spriteId]
      if (sprite.src instanceof SpriteAnimation) {
        needToErase.push(spriteId)
        needToDraw.push(spriteId)
      } else if (sprite.prevRect === null) {
        needToDraw.push(spriteId)
      } else if (sprite.currentRect === null) {
        needToErase.push(spriteId)
      } else if (sprite.currentRect.equals(sprite.prevRect)) {
        needToDraw.push(spriteId)
      } else {
        needToErase.push(spriteId)
        needToDraw.push(spriteId)
      }
    }
    for (const spriteId of needToErase) {
      const r: Rectangle = this.activeSprites[spriteId].prevRect as Rectangle
      if (r.overlapWith(this.canvasRect)) {
        this.ctx.clearRect(r.pos.x, r.pos.y, r.size.x, r.size.y)
      }
    }
    needToDraw.sort((s1, s2) => this.activeSprites[s1].zIndex - this.activeSprites[s2].zIndex)
    for (const spriteId of needToDraw) {
      const sprite: Sprite = this.activeSprites[spriteId]
      if (sprite.currentRect !== null && sprite.currentRect.overlapWith(this.canvasRect)) {
        sprite.drawOnCtx(this.ctx, sprite.currentRect as Rectangle)
      }
    }
  }
}
