import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_space = _resolveComponent("a-space")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_typography_title, { style: {"margin":"15px"} }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("施工中，敬请期待")
      ])),
      _: 1
    }),
    _createVNode(_component_a_space, { align: "center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          id: _ctx.id,
          class: "under-construction-animation"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    })
  ], 64))
}