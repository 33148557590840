import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "carousel-container" }
const _hoisted_2 = {
  class: "custom-slick-arrow",
  style: {"left":"2vw","z-index":"1"}
}
const _hoisted_3 = {
  class: "custom-slick-arrow",
  style: {"right":"2vw"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_a_carousel = _resolveComponent("a-carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_carousel, {
      autoplay: "",
      effect: "fade",
      arrows: ""
    }, {
      prevArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Arrow, { circled: "true" })
        ])
      ]),
      nextArrow: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Arrow, {
            circled: "true",
            direction: "right"
          })
        ])
      ]),
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "main" }, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              class: "carousel-bg",
              src: "static/image/THO1-main-cut.png",
              alt: "Poster of main event"
            }),
            _createElementVNode("div", { class: "overlay" }, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  class: "logo",
                  src: "static/image/THO1-logo.png",
                  alt: "Event LOGO"
                })
              ])
            ])
          ])
        ], -1)),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "special" }, [
          _createElementVNode("div", null, [
            _createElementVNode("img", {
              class: "carousel-bg",
              src: "static/image/THO1-special-cut.png",
              alt: "Poster of special event"
            }),
            _createElementVNode("div", { class: "overlay" }, [
              _createElementVNode("div", null, [
                _createElementVNode("img", {
                  class: "logo",
                  src: "static/image/THO1-logo.png",
                  alt: "Event LOGO",
                  style: {"filter":"invert(1.0)"}
                })
              ])
            ])
          ])
        ], -1))
      ]),
      _: 1
    })
  ]))
}