<template>
  <TicketImportManager />
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import TicketImportManager from '../components/TicketImportManager.vue';

@Options({
  components: {
    TicketImportManager
  }
})
export default class TicketImport extends Vue{};
</script>

<style>

</style>