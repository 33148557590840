
import { Options, Vue } from 'vue-class-component'
import Banner from '../components/Banner.vue'
import Navbar from '../components/Navbar.vue'
import DodgeFrame from '../components/DodgeFrame.vue'
import Footer from './Footer.vue'

type AugmentedWindow = Window & {
  dodgeScore: number;
  dodgeDead: number; 
}

const hiScoreKey = 'dodge-hiscore';

@Options({
  components: {
    Banner,
    Navbar,
    DodgeFrame,
    Footer
  },
})
export default class Dodge extends Vue {
  score = 0;
  window = window as any as AugmentedWindow;
  highestScore = 0;
  dead = false;
  lastDeath = 0;

  update = () => {
    if (typeof this.window.dodgeScore === "undefined" || typeof this.window.dodgeDead === "undefined") {
      this.score = 0;
      this.highestScore = Number(this.window.localStorage.getItem(hiScoreKey));
      this.dead = false;
    } else {
      this.score = Math.floor(500 * this.window.dodgeScore);
      this.highestScore = Math.max(this.score, this.highestScore);
      this.dead = (!isNaN(this.window.dodgeDead) && this.window.dodgeDead === 114514);
      if (this.dead && Date.now() - this.lastDeath > 1000) {
        this.lastDeath = Date.now();
        const storedHiScore = Number(this.window.localStorage.getItem(hiScoreKey));
        if (storedHiScore < this.highestScore) {
          this.window.localStorage.setItem(hiScoreKey, this.highestScore.toString());
        }
      }
    }
    this.window.requestAnimationFrame(this.update);
  }

  scoreString(n?: number) {
    if (typeof n === "undefined") return '000,000,000';
    return `${Math.floor(n / 1000000).toString().padStart(3, '0')}, ${Math.floor((n % 1000000) / 1000).toString().padStart(3, '0')}, ${(n % 1000).toString().padStart(3, '0')}`;
  }

  status() {
    if (typeof this.dead === "undefined") return '';
    return this.dead ? '君殁矣！按SPACE来重新开始' : '您还活着';
  }

  statusStyle() {
    if (typeof this.dead === "undefined") return '';
    return this.dead ? 'red' : 'black';
  }

  mounted() {
    this.highestScore = Number(this.window.localStorage.getItem(hiScoreKey));
    console.log(this.highestScore)
    this.window.requestAnimationFrame(this.update);
  }
}
