
import { Options, Vue } from 'vue-class-component'
import Navbar from '../components/Navbar.vue'
import Banner from '../components/Banner.vue'
import UnderConstruction from '../components/UnderConstruction.vue'
import Header from '../components/Header.vue'
import VenueInfo from '../components/VenueInfo.vue'
import EventDescription from '../components/EventDescription.vue'
import Footer from './Footer.vue'

@Options({
  components: {
    Navbar,
    Banner,
    UnderConstruction,
    Header,
    VenueInfo,
    EventDescription,
    Footer
  },
  created: () => {
    document.title = '槐南故事分享'
  }
})
export default class Home extends Vue {}
