import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/main.css'

import Antd from 'ant-design-vue'
import {
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons-vue'
import 'ant-design-vue/dist/antd.css'
import Vue3SmoothScroll from 'vue3-smooth-scroll'

const app = createApp(App)

app.use(store)
  .use(router)
  .use(Antd)
  .use(Vue3SmoothScroll)
  .component(UploadOutlined.name, UploadOutlined)
  .component(LoadingOutlined.name, LoadingOutlined)
  .component(PlusOutlined.name, PlusOutlined)
  .component(DeleteOutlined.name, DeleteOutlined)
  .mount('#app')

window.postMessage({
  devtoolsEnabled: true,
  vueDetected: true
}, '*')
